<template>
	<Header/>
	<!-- main-content -->
    <main class="main-content">
      <!-- sub-banner -->
      <section class="sub-banner form-floating bg-black-3">
        <div class="container form-floating">
          <div class="row align-items-center">
            <div class="col-md-4 col-lg-4">
              <div class="sub-banner-img">
                <img class="img-shadow-2" src="/images/upload/04-record-result.png" alt="" />
              </div>
            </div><!--/.col-->
            <div class="col-md-8 col-lg-6">
              <div class="sub-banner-content text-center ps-lg-3">
                <div class="sub-banner-header">
                  <p class="sub-banner-sub-label">ZzzMeter, a sleep breath sound measurement application</p>
                  <div class="logo-zzzmeter">
                    <span class="logo-img">
                    <img src="/images/logo_84x84.png" alt="..." />
                    </span>
                    <span class="logo-text">ZzzMeter</span>
                  </div>
                </div>
                <button class="a-btn cursor-auto"><span>For corporates clients</span></button>
                <h2 class="sub-banner-title">Please feel free to request<br />Documents and Customer Service Inquiry</h2>
                <div class="sub-banner-text">We have plans for corporations depending on the size of your employees<br />Please contact us for more information.</div>
              </div>
            </div><!--/.col-->
          </div>
        </div>
      </section>
      <!-- e: sub-banner -->

      <!-- section-contact -->
      <section class="section-contact">
        <div class="container form-floating">
          <div class="contact-box">
            <ul class="nav nav-tabs nav-tabs-custom">
              <li class="nav-item">
                <button class="nav-link" v-bind:class="{ active: this.$route.query.tab == 'inquiry' }" id="inquiry-tab" data-bs-toggle="tab" data-bs-target="#inquiry"
                  type="button" role="tab" aria-controls="inquiry" aria-selected="true" @click="changeTab('inquiry')">
                  <span>Inquiry</span>
                </button>
              </li>
              <li class="nav-item">
                <button class="nav-link" v-bind:class="{ active: this.$route.query.tab == 'form' }" id="form-tab" data-bs-toggle="tab" data-bs-target="#form" type="button"
                  role="tab" aria-controls="form" aria-selected="false" @click="changeTab('form')">
                  <span>Request Documents</span>
                </button>
              </li>
            </ul>
            <div class="tab-content spacing" id="nav-tabContent">
              <div class="tab-pane fade" v-bind:class="{ 'active show': this.$route.query.tab == 'inquiry' }" id="inquiry" role="tabpanel" aria-labelledby="inquiry-tab">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contact-info ps-3 mb-4">
                      <h3 class="contact-info-title">Inquiry</h3>
                      <div class="contact-info-text">
                        We have a special plan for corporates clients for the purpose of health support for employees. Please fill out the form and send it if you are interested in finding out more or have further question
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="contact-form">
                      <form class="form">
                        <div class="row gx-2">
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label for="formControl1" class="form-label">Company name <span
                                  class="text-required">required</span></label>
                              <input type="text" class="form-control" id="formControl1" placeholder="" v-model="fields.company_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.company_name.$error">This field is required</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label for="formControl1" class="form-label">Employee size <span
                                  class="text-any">optional</span></label>
                              <select class="form-select" v-model="fields.company_size">
                                <option value=""> --- </option>
                                <option value="1-10"> 1 - 10 </option>
                                <option value="10-50">10 - 50 </option>
                                <option value="50-100">50 - 100 </option>
                                <option value=">100"> > 100 </option>
                              </select>
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="row gx-2">
                          <div class="col-12">
                            <label class="form-label">Name of person in charge <span class="text-required">required</span></label>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" placeholder="Family name" v-model="fields.first_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.first_name.$error || v$.fields.last_name.$error">This field is required</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" id="formControl2" placeholder="Last name" v-model="fields.last_name">
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="form-label">Title <span class="text-any">optional</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.position">
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">Email <span class="text-required" >required</span></label>
                          <input type="email" class="form-control" placeholder="" v-model="fields.email" required>
                          <div class="invalid-feedback" style="display: block" v-if="v$.fields.email.$error">The email field is required</div>
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">Phone number <span class="text-any">optional</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.phone_no">
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">Inquiry content <span class="text-required">required</span></label>
                          <textarea class="form-control" rows="6" cols="10" v-model="fields.content"></textarea>
                          <div class="invalid-feedback" style="display: block" v-if="v$.fields.content.$error">This field is required</div>
                        </div><!-- form-group -->
                        <div class="form-check form-check-custom">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="accepted">
                          <label class="form-check-label" for="exampleCheck1">I agree to ZzzMeter®<a href="/privacy-policy">
                              privacy policy</a> regarding the handling of personal information.</label>
                        </div><!-- form-check -->
                        <div class="form-bottom">
                          <button type="button" class="btn btn-custom btn-xl btn-purle-1 w-100 rounded-pill btn-arrow" @click="submitForm" :disabled="!this.accepted">Send
                            <i class="arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- tab 1 -->
              <div class="tab-pane fade" v-bind:class="{ 'active show': this.$route.query.tab == 'form' }"  id="form" role="tabpanel" aria-labelledby="form-tab">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contact-info ps-3 mb-4">
                      <h3 class="contact-info-title">Request Documents</h3>
                      <div class="contact-info-text">
                        Please fill out the form and send it with the address to send the materials and send.<br />
                        We will send you the information about our services.</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="contact-form">
                      <form class="form">
                        <div class="row gx-2">
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label class="form-label">Company name <span class="text-required">required</span></label>
                              <input type="text" class="form-control" placeholder="" v-model="fields.company_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.company_name.$error">This field is required</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label class="form-label">Employee size <span class="text-any">optional</span></label>
                              <select class="form-select" v-model="fields.company_size">
                                <option value=""> --- </option>
                                <option value="1-10"> 1 - 10 </option>
                                <option value="10-50">10 - 50 </option>
                                <option value="50-100">50 - 100 </option>
                                <option value=">100"> > 100 </option>
                              </select>
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="row gx-2">
                          <div class="col-12">
                            <label class="form-label">Name of person in charge <span class="text-required">required</span></label>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" placeholder="Family name" v-model="fields.first_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.first_name.$error || v$.fields.last_name.$error">This field is required</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" id="formControl2" placeholder="Last name" v-model="fields.last_name">
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="form-label">Title <span class="text-any">optional</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.position">
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">Email <span class="text-required">required</span></label>
                          <input type="email" class="form-control" placeholder="" v-model="fields.email">
                          <div class="invalid-feedback" style="display: block" v-if="v$.fields.email.$error">The email field is required</div>
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">Phone number <span class="text-any">optional</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.phone_no">
                        </div><!-- form-group -->
                        <div class="form-check form-check-custom">
                          <input type="checkbox" class="form-check-input" id="exampleCheck2" v-model="accepted">
                          <label class="form-check-label" for="exampleCheck2">I agree to ZzzMeter®<a href="/privacy-policy">
                              privacy policy</a> regarding the handling of personal information.</label>
                        </div><!-- form-check -->
                        <div class="form-bottom">
                          <button type="button" @click="submitForm" class="btn btn-custom btn-xl btn-purle-1 w-100 rounded-pill btn-arrow" :disabled="!this.accepted">Send
                            <i class="arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- tab 2 -->
            </div>
          </div>
        </div>
      </section>
      <!-- e: section-contact -->
    </main>
    <!-- e: main-content -->
	<Footer/>
</template>

<script>

import Header from '../../components/en/Header.vue'
import Footer from '../../components/en/Footer.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from 'axios'

export default {
  name: 'zm-contact',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      v$: useVuelidate(),
      fields: {
        company_name: "",
        company_size: "",
        first_name: "",
        last_name: "",
        email: "",
        content: "",
        phone_no: "",
        position: "",
        type: this.$route.query.tab,
      },
      accepted: false,
    };
  },
  validations() {
    return {
      fields: {
        company_name: { required },
        first_name: { required },
        last_name: { required },
        email: { required, email },
        content: this.fields.type == 'inquiry'? { required } : {},
        phone_no: {},
        company_size: {},
      },
      accepted: { required },
    };
  },
  methods: {
    changeTab(type) {
      this.fields.type = type;
    },
    async submitForm() {
      this.v$.fields.$validate();
      const api = process.env.VUE_APP_API_URL + "contact_mail";
      if (!this.v$.fields.$error && this.accepted) {
          await axios.post(api, this.fields)
          .then( () => {
            window.location.href = '/thanks';
          })
          .catch( error => {
            alert('Something went wrong \n Please try again later.')
            console.log("ERROR", error);
          })
      } else {
        console.log("Form validation failed");
      }
    },
  },
}
</script>