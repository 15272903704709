import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/jp/Home'
import Thanks from '../views/jp/Thanks'
import Terms from '../views/jp/Terms'
import PrivacyPolicy from '../views/jp/PrivacyPolicy'
import Contact from '../views/jp/Contact'
import enHome from '../views/en/Home'
import enThanks from '../views/en/Thanks'
import enTerms from '../views/en/Terms'
import enPrivacyPolicy from '../views/en/PrivacyPolicy'
import enContact from '../views/en/Contact'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // jp
    {
      path: '/',
      component: Home
    },
    {
      path: '/thanks',
      component: Thanks
    },
    {
      path: '/terms',
      component: Terms
    },
    {
      path: '/privacy-policy',
      component: PrivacyPolicy
    },
    {
      path: '/contact',
      component: Contact
    },
    // eng
    {
      path: '/en',
      component: enHome
    },
    {
      path: '/en/thanks',
      component: enThanks
    },
    {
      path: '/en/terms',
      component: enTerms
    },
    {
      path: '/en/privacy-policy',
      component: enPrivacyPolicy
    },
    {
      path: '/en/contact',
      component: enContact
    },
  ]
})

export default router