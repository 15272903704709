<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'App',
    mounted () {
      this.controlBodyClass();
    },
    watch: {
      $route (to) {
        window.scrollTo(0, 0)
        var bodyElem = document.getElementsByTagName('body')[0];
        if (to.path == '/thanks' || to.path == '/en/thanks') {
          bodyElem.classList.add("bg-body-grey");
        } else {
          bodyElem.classList.remove("bg-body-grey");
        }
        if (to.path == '/' || to.path == '/en/') {
          bodyElem.classList.add("home");
        } else {
          bodyElem.classList.remove("home");
        }
      }
    },
    methods: {
      controlBodyClass: () => {
        var lang = (location.pathname.split('/')[1] == 'en') ? 'en' : 'jp';
        var bodyElement = document.querySelector('body');
        if (lang == 'en' && !bodyElement.classList.contains('eng')) {
          bodyElement.classList.add('eng');
        }else {
          bodyElement.classList.remove('eng');
        }
      },
    }
  }

</script>
