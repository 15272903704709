<template>
  <Header2 />
  <!-- main-content -->
  <main class="main-content">
    <div class="container">
      <div class="thanks-content">
        <h2>Thanks</h2>
        <div class="expert">
          <p>
            Thank you for your inquiry!<br />
            The information you provided has been successfully submitted.<br />
            Our representative will contact you via email shortly. Please wait for our contact.
          </p>
        </div>
      </div>
      <!--/.thanks-content-->
    </div>
  </main>
  <!-- e: main-content -->
  <Footer2 />
</template>

<script>

import Header2 from '../../components/en/Header2.vue'
import Footer2 from '../../components/en/Footer2.vue'

export default {
  name: 'zm-thanks',
  components: {
    Header2,
    Footer2
  },
}
</script>