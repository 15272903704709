<template>
  <Header />
  <!-- main-content -->
  <main class="main-content">
    <div class="primary-content">
      <div class="container">
        <div class="entry">
          <h1 class="entry-title">利用規約</h1>
          <div class="entry-body">
            <section>
              制定：2023年3月1日<br />
              改訂：2023年7月1日
            </section>
            <section>
              本ZzzMeter利用規約（以下「本規約」といいます。）は、株式会社オクチィ（以下「当社」といいます。）が提供するアプリケーションソフト「Zzz
              Meter｣（以下「本アプリ」といいます。）を通じて、また、本アプリに付帯して当社が提供するサービス（以下「本サービス」といいます。）の利用に関する条件等を、本サービスの利用者（以下「利用者」といいます。）と当社との間で定めるものです。本サービスの利用にあたっては、本規約をお読みいただいた上で、本規約の内容に同意いただく必要があります。
            </section>
            <section>
              <h3>第1条（本規約の適用）</h3>
              <ul>
                <li>
                  1.本規約は、本サービスの提供を希望して、本アプリを別途当社が指定する仕様を満たした自己の端末（以下「対応端末」といいます。）にダウンロードした利用者に適用されます。利用者が、本サービスの法人としての利用契約（以下「法人契約」といいます。）を締結した法人その他の団体（以下「法人会員」といいます。）の役員又は従業員その他法人会員に属する者である場合も、当該利用者には本規約が提供されます。
                </li>
                <li>
                  2.利用者が当社から本サービスの提供を受けるための契約（以下「利用契約」といいます。）は、利用者が、本アプリを対応端末にダウンロードし、本サービスの利用を開始した時点で当社と利用者との間に成立するものとします。利用契約が成立した時点で、利用者は本規約の内容に同意したものとみなされます。利用者が未成年である場合は、親権者等法定代理人の同意を得た上で本サービスを利用してください。
                </li>
              </ul>
            </section>
            <section>
              <h3>第2条（本サービスの内容）</h3>
              <ul>
                <li>
                  1.本サービスは、次の各号に掲げる機能（以下「本機能」といいます。）を提供するサービスです。本サービスは、診断行為、診療行為及び治療行為を提供するものではなく、医業を目的に行うものでもありません。なお、対応端末の種別、本アプリのバージョン又は設定によっては、利用できる機能に制限がある場合があり、当社は、本アプリを通じた本機能の提供を保証するものではありません。
                  <ol>
                    <li>(1)利用者が、睡眠中の呼吸音を録音した音声データをアップロードする機能</li>
                    <li>(2)利用者が、当社が指定する方法にて顔と口腔内を撮影した画像ファイル（前号の音声データとあわせて、以下「ユーザーコンテンツ」といいます。）をアップロードする機能</li>
                    <li>(3)利用者の呼吸音その他利用者から提供を受けた情報を分析し、呼吸の状態等を視覚情報等（以下「分析結果」といいます。）により表示する機能</li>
                    <li>(4)その他口腔関連サービスに関連する機能</li>
                  </ol>
                </li>
                <li>2.本サービスの利用には、対応端末及び本アプリが必要です。</li>
                <li>
                  3.アップロードされた睡眠中の呼吸音を録音した音声データが、本サービスにおいて解析不能なデータであると当社が判断した場合、本アプリ上に音声データの録り直しを求める画面が表示されます（録り直しの上限は2回とします。）。本項に基づきなされた2回目の再アップロードにかかる音声データが、本サービスにおいて解析不能なデータであると当社が判断した場合、再度の音声データの録り直しを行うことはできず、分析結果を提供できないことにつき、利用者はあらかじめ承諾するものとします。
                </li>
                <li>4.呼吸音の録音環境、顔と口腔内の画像の撮影状況等により、分析結果を提供できない場合、又は正しい分析結果を提供できない場合があります。</li>
                <li>5.当社は、本アプリのバージョンアップ等に伴い、利用者にあらかじめ通知することなく、本アプリの使用に必要な対応端末の種別を変更できるものとします。</li>
                <li>6.本サービスの利用可能地域は日本国内とします。当社は、利用者による本サービスの利用は、全て日本国内で行われたものとみなして取り扱います。</li>
              </ul>
            </section>
            <section>
              <h3>第3条（登録）</h3>
              <ul>
                <li>
                  1.本サービスの利用を希望する方（以下「申込者」といいます。）は、本アプリにおいて、当社指定事項（以下「登録情報」といいます。）を入力して当社に提供することで、利用登録の申込みをすることができます。利用登録は、本アプリ上において、利用登録完了通知がされた時点で完了するものとします。未成年者による利用登録には、当社の指定する方法により、親権者又は未成年後見人による事前の同意（本規約等への同意を含みます。）を要します。成年被後見人は、利用登録を行うことができません。なお、本項の利用登録の申込みは、法人会員の役員又は従業員その他法人会員に属する者との関係では、法人会員が行うものとします。
                </li>
                <li>
                  2.当社は、以下の各号のいずれかに該当する場合、利用登録申込みを承諾せず、又は利用登録を取り消すことができるものとします。
                  <ol>
                    <li>(1)登録情報の全部又は一部に虚偽、不正確又は誤りがある場合</li>
                    <li>(2)申込者が、過去に本規約に違反したこと等により、利用登録の停止又は失効を受けている場合</li>
                    <li>(3)未成年者による親権者又は未成年後見人の承諾を得ない利用登録又はその申込みが判明した場合</li>
                    <li>(4)成年被後見人であることが判明した場合</li>
                    <li>(5)その他、利用登録を承認することが本サービスの運営・管理上、不適当であると当社が合理的な根拠に基づき合理的に判断する場合</li>
                  </ol>
                </li>
                <li>3.当社は、本サービスにおけるセキュリティ確保のため、本サービスを利用するために必要なパスワードの設定を利用者に求めるものとします。</li>
                <li>
                  4.利用者は、前項に定めるパスワードの使用及び管理について一切の責任を負うものとし、第三者に開示、貸与、譲渡等をしてはならないものとします。利用者のパスワードを使用して本サービスにてなされる一切の行為は、当該利用者による行為であるとみなされます。パスワードを第三者が使用したことにより生じた損害の責任は当該利用者が負うものとし、当社は、当社に故意又は重過失がある場合を除き一切責任を負いません。
                </li>
                <li>5.利用者は、登録情報について、自ら責任を負うものとします。登録情報に変更が生じた場合には、利用者は、本アプリにおいて、直ちに情報の更新を行い、常に最新の情報を当社に提供するものとします。</li>
              </ul>
            </section>
            <section>
              <h3>第4条（利用料）</h3>
              <ul>
                <li>
                  1.利用者は、別途合意する場合又は利用者の属する法人会員との法人契約において当該法人会員が支払う旨合意されている場合を除き、本サービスに係る当社の定める利用料金を、下記各号のうち、当社が指定する方法によって支払うものとします。
                  <ol>
                    <li>(1)Apple Inc.及びiTunes株式会社の定めるiTunes Store又はApp Storeでの決済手段</li>
                    <li>(2)Googleの定めるGoogle Walletでの決済手段</li>
                    <li>(3)クレジットカードを利用しての決済手段</li>
                    <li>(4)当社が指定する第三者が提供する決済サービスを利用した決済手段</li>
                    <li>(5)その他当社の定める決済手段</li>
                  </ol>
                </li>
                <li>
                  2.当社の定める利用料金は変更されることがあります。当社が利用料金を変更する場合、当社は本サービス上において、利用料金変更の適用開始日の3か月以上前に、変更後の利用料金及び利用料金変更の適用開始日を利用者が知り得る状態に置くものとします。
                </li>
                <li>3.本サービスの利用（本アプリのダウンロード及びバージョンアップを含みますが、これらに限られません。）に伴いデータ通信が発生した場合の通信料は、利用者が負担するものとします。</li>
              </ul>
            </section>
            <section>
              <h3>第5条（情報の取扱い）</h3>
              当社は、本サービスの提供に際して知り得た利用者の個人情報（個人情報の保護に関する法律（平成15年法律第57号）第2条第1項に定める個人情報をいいます。）について、当社が別途定める「Zzz
              Meterプライバシーポリシー」（以下「本プライバシーポリシー」といいます。）の定めに従って適切に取り扱います。
            </section>
            <section>
              <h3>第6条（知的財産権）</h3>
              <ul>
                <li>
                  1.本サービスにおいて提供されるコンテンツ（分析結果、文章その他の情報を含み、ユーザーコンテンツを除きます。）、商標、ロゴマーク等（以下、総称して「本サービスコンテンツ等」といいます。）の一切の著作権その他の知的財産権は、当社又は原権利者に帰属します。利用契約の締結は、利用者に対してこれらの権利を移転するものではなく、利用者は、利用契約に基づく本機能の利用に必要な範囲に限って、本サービス及び本サービスコンテンツ等を使用することができるものとします。
                </li>
                <li>2.利用者は、本サービスコンテンツ等について、その全部又は一部を問わず、無断で複製、複写、転載、公衆送信、改変、翻案、配布、販売等、当社又は原権利者の権利を侵害する行為を行ってはならないものとします。</li>
              </ul>
            </section>
            <section>
              <h3>第7条（ユーザーコンテンツの取扱い）</h3>
              <ul>
                <li>1.ユーザーコンテンツの著作権は、利用者に帰属します。</li>
                <li>
                  2.利用者は、当社及び当社から権利を承継し又は許諾された者に対して、本サービスの提供及び改善、当社における新サービスの開発及びAI開発、個人を識別できない形式に加工した統計データの作成、その他本プライバシーポリシーにおいて定める利用目的のために、ユーザーコンテンツを無償で利用すること（複製、複写、改変を含みます。）を許諾したものとします。また、利用者は、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないものとします。
                </li>
                <li>
                  3.利用者は、自らのユーザーコンテンツについて、自ら撮影及び保存し、本サービスにおいて利用（当社による本サービスの提供、本サービスの機能改善及びAI開発等のための利用を含みます。）するために必要な権利を自ら保有し、又は第三者より許諾を得ていること、並びに第三者の知的財産権その他の権利を侵害していないことを表明し保証するものとします。
                </li>
                <li>
                  4.当社は、ユーザーコンテンツが、当社又は第三者の知的財産権その他の権利を侵害する旨の通知を受けた場合、又は当社が本サービスとの関係において当該ユーザーコンテンツが不適切であると判断した場合、利用者に事前の通知をすることなく、本機能の提供を停止し、当該ユーザーコンテンツを当社が本サービスを提供するために設置するサーバー（以下「本サービスサーバー」といいます。）から削除することができるものとします。
                </li>
                <li>
                  5.利用者は、ユーザーコンテンツに関し、第三者の知的財産権その他の権利を侵害している、又は侵害している可能性があるとして当社と第三者との間で問い合わせ、苦情、紛争等（以下、総称して「紛争等」といいます。）が発生したときは、訴訟費用を含む全ての費用を負担して責任をもって当該紛争等を処理、解決するものとし、当社が被った損害を賠償するものとします。また、利用者と第三者との間で紛争等が発生した場合であっても、当社は当該紛争等の解決義務を負わないものとし、利用者は、自らの費用と責任において当該紛争等を解決するものとします。
                </li>
              </ul>
            </section>
            <section>
              <h3>第8条（遵守事項）</h3>
              <ul>
                <li>
                  1.利用者は、本サービスの利用にあたって次の各号に該当する行為を行ってはならないものとします。
                  <ol>
                    <li>(1)当社若しくは第三者の著作権、特許権、実用新案権、意匠権、商標権等の知的財産権、プライバシーその他の権利若しくは利益を侵害する行為、又はそれらのおそれのある行為</li>
                    <li>(2)公序良俗に反する行為若しくは公序良俗に反する情報を第三者に提供する行為、又はそれらのおそれのある行為</li>
                    <li>(3)犯罪的行為、犯罪的行為に結びつく行為若しくは法令に違反する行為、又は、それらのおそれのある行為</li>
                    <li>(4)事実に反する情報又はそのおそれのある情報を提供する行為</li>
                    <li>(5)当社若しくは第三者の名誉若しくは信用を毀損する行為、又はそれらのおそれのある行為</li>
                    <li>(6)アカウントを不正に使用する行為</li>
                    <li>
                      (7)本サービスサーバーその他の当社の設備に対して過度な負担を与える行為、当社による本サービスの提供を不能にする行為、その他当社による本サービスの提供に支障を与え、若しくはその運営を妨げる行為、又は、それらのおそれのある行為
                    </li>
                    <li>(8)コンピュータウイルス等有害なプログラムを、本サービスを通じて、若しくは本サービスに関連して使用し、若しくは提供する行為、又は、それらのおそれのある行為</li>
                    <li>(9)本サービスを利用可能地域以外の地域で利用する行為</li>
                    <li>
                      (10)本サービス、本アプリ又は本サービスコンテンツ等について、複製、公衆送信（自動公衆送信の場合にあっては、送信可能化を含みます。）、伝達、譲渡、貸与、変形、翻案等の利用を行う等、本サービス又は本サービスコンテンツを第6条（知的財産権）に定める範囲を超えて利用又は使用する行為
                    </li>
                    <li>
                      (11)本サービス、本アプリ又は本サービスコンテンツ等について、改変若しくは改ざんを行い、又は、逆コンパイル、逆アセンブル等のリバースエンジニアリング（主に、内容を解析して、人間が読み取り可能な形に変換することを指します。）を行う行為
                    </li>
                    <li>(12)本サービス、本アプリ又は本サービスコンテンツ等に付されている著作権表示その他の権利表示を除去、又は、変更する行為</li>
                    <li>(13)当社の定める手順に反する方法で本アプリをインストールし、利用する行為。その他、本アプリを、本規約に反する方法又は反するおそれのある方法で利用する行為</li>
                    <li>
                      (14)利用者が、以下の情報を含む動画ファイルを、本アプリを使用して撮影し、又は本アプリ上に保存する行為
                      <ol>
                        <li>1.当社又は第三者の権利及び財産を侵害し若しくはそれらに対して損害を与え、又はそれらのおそれのある情報</li>
                        <li>2.当社、本アプリを含む当社製品又はサービスへの信用を毀損し若しくは当社の業務を妨害し、又はそれらのおそれのある情報</li>
                        <li>3.第三者の通信の秘密やプライバシーを侵害する情報</li>
                        <li>4.第三者を誹謗・中傷し、又は差別する情報</li>
                        <li>5.犯罪や不法行為、危険行為に属する情報及びそれらを教唆、幇助する情報</li>
                        <li>6.著作権等、当社又は第三者の知的財産権を侵害する情報</li>
                        <li>7.当社又は第三者の秘密情報や財産等の盗取、権利の侵害を目的とした有害プログラムを含んだ情報</li>
                        <li>8.不法、有害、脅迫、虐待、人種差別、中傷、名誉棄損、侮辱、ハラスメント、扇動を構成し、若しくはそれらの結果を生じさせるおそれのある内容をもつ情報、不快感を与えることを意図した表現を含む情報</li>
                        <li>9.事実に反する、又は存在しないことが明らかな情報</li>
                        <li>10.利用者自身がコントロール可能な権利を持たない情報</li>
                        <li>11.公共の利益又は個人の権利を侵害する情報</li>
                        <li>12.わいせつ、児童ポルノ又は児童虐待にあたる動画等の情報</li>
                        <li>13.法令に違反する情報</li>
                        <li>14.その他当社が不適切であると合理的な根拠に基づき合理的に判断する情報</li>
                      </ol>
                    </li>
                    <li>(15)その他当社が不適切であると合理的な根拠に基づき合理的に判断する行為</li>
                  </ol>
                </li>
                <li>2.利用者は、当社が事前に承諾した場合を除き、本アプリ及び本サービスを個人的かつ非商業的な利用に限り利用することができるものとし、営利目的及び商業目的のために利用することはできません。</li>
                <li>
                  3.利用者が本アプリ又は本サービスを利用したことに起因して、当社が直接的若しくは間接的に、何らかの損失若しくは損害を被り、又は費用を負担した場合、その者は、当社の請求に従って直ちにこれを補償しなければならないものとします。
                </li>
                <li>
                  4.前各項に定めるほか、利用者は自己の責任において本アプリ及び本サービスを利用するものとし、本規約に別に定める場合を除き、本アプリにおいて又は本アプリを利用したことに起因して行った一切の行為及びその結果について一切の責任を負うものとします。
                </li>
              </ul>
            </section>
            <section>
              <h3>第9条（提供中断）</h3>
              <ul>
                <li>
                  1.当社は、次の各号のいずれかに該当すると当社が判断したときは、本サービスの全部又は一部の提供を中断することがあります。
                  <ol>
                    <li>(1)天災地変等の不可抗力により本サービスが提供できなくなくなったとき</li>
                    <li>(2)本サービスサーバーその他本サービスに関する機器、設備等の保守、工事等を実施する必要があるとき</li>
                    <li>(3)本サービスサーバーその他本サービスに関する機器、設備等に故障、障害等が発生したとき</li>
                    <li>(4)災害の予防若しくは救援、交通、通信若しくは電力の供給の確保又は秩序の維持に必要な通信その他公共のために必要があるとき</li>
                    <li>(5)当社の運用上又は技術上、本サービスの全部又は一部の提供を中断する必要があるとき</li>
                  </ol>
                </li>
                <li>2.当社は、前項に定めるほか、本サービスの運用上必要な範囲において、本サービスの利用の制限等を行うことができるものとします。</li>
                <li>
                  3.当社は、第1項に基づく本サービスの全部若しくは一部の提供の中断又は前項に定める利用の制限等を計画している場合は、その旨を本アプリ上に掲載する方法により利用者に周知するものとします。ただし、緊急性がある等やむを得ない場合は当該周知を行わないことがあります。
                </li>
              </ul>
            </section>
            <section>
              <h3>第10条（提供停止等）</h3>
              <ul>
                <li>
                  1.当社は、利用者の属する法人会員又は利用者が次の各号のいずれかに該当すると当社が判断したときは、事前の通知を行うことなく、当該利用者に対する本サービスの全部又は一部の提供を停止することができるものとします。
                  <ol>
                    <li>(1)第8条（遵守事項）に違反したとき</li>
                    <li>(2)第三者による本サービスの利用に支障を与える又はそのおそれのある行為があったとき</li>
                    <li>(3)その他本規約に違反したとき又は当社の業務の遂行上支障があるとき</li>
                  </ol>
                </li>
                <li>
                  2.当社は、利用者の属する法人会員又は利用者に対し、前項の措置に替えて、又は前項の措置とともに、期限を定めて当該事由を解消することを求めることができるものとします。ただし、本項の定めは、当社が第11条（解約）第1項に基づき利用登録を取り消すことを妨げるものではありません。
                </li>
              </ul>
            </section>
            <section>
              <h3>第11条（解約）</h3>
              <ul>
                <li>
                  1.当社は、利用者の属する法人会員又は利用者が次の各号のいずれかに該当すると当社が判断したときは、何らの催告を行うことなく、当該利用者の登録を直ちに取消し、被った損害の賠償を請求することができるものとします。
                  <ol>
                    <li>
                      (1)第10条（提供停止等）第1項各号に定める事由のいずれかに該当するとして、本サービスの提供が停止された場合において、当該事由が当社の業務に支障を及ぼすおそれがあるとき、又は当社が指定する期限までに当該停止の原因となった事由を解消しないとき
                    </li>
                    <li>(2)第18条（反社会的勢力の排除等）に違反したとき</li>
                    <li>(3)本規約に基づく義務を履行する見込みがないと認められるとき</li>
                    <li>(4)当社に重大な危害若しくは損害を及ぼしたとき、又はそのおそれがあるとき</li>
                    <li>(5)その他本サービスの提供を継続できないと認められる相当の事由があるとき</li>
                  </ol>
                </li>
                <li>2.前項の定めに基づき、当社が利用者の登録を取り消した場合、当該利用者は直ちに本サービスを利用することができなくなり、支払い済みの利用料の返金は行われません。</li>
              </ul>
            </section>
            <section>
              <h3>第12条（退会）</h3>
              <ul>
                <li>1.利用者が本アプリの利用登録の解除を希望する場合は、当社所定の退会手続を行い解除の意思を示す方法により登録解除を行うことができます。</li>
                <li>2.利用者が登録解除を行った場合、当該利用者は直ちに本サービスを利用することができなくなり、支払い済みの利用料の返金は行われません。</li>
              </ul>
            </section>
            <section>
              <h3>第13条（解約・退会時の措置等）</h3>
              <ul>
                <li>
                  1.当社による利用者の登録の取り消し又は利用者による登録解除により、利用者が本サービスを利用することができなくなった場合（法人会員に属している利用者に関しては、当該法人会員が締結している法人契約が終了した場合を含みます。）、利用契約は終了するものとし、利用者は、速やかに、自己の占有又は管理下にある本アプリを再生不能な形で消去するものとします。
                </li>
                <li>2.利用者は、前項の場合であっても、自らのユーザーコンテンツが削除されず当社において保持・利用される場合があることをあらかじめ承諾するものとします。</li>
                <li>
                  3.利用契約の終了にかかわらず、第6条（知的財産権）、第7条（ユーザーコンテンツの取扱い）、第8条（遵守事項）第3項及び第4項、本条、第16条（本サービスに関する不保証等）、第17条（当社の免責事項）、第20条（分離可能性）、第21条（権利の譲渡等）、及び第22条（準拠法及び管轄裁判所）の定めは、なお有効に存続するものとします。
                </li>
              </ul>
            </section>
            <section>
              <h3>第14条（本サービスの変更、追加及び廃止）</h3>
              <ul>
                <li>1.当社は、当社の都合によりいつでも、本サービスの全部又は一部を変更又は追加することができるものとします。</li>
                <li>
                  2.当社は、本サービスの全部又は一部を廃止することができるものとします。当社は、本項による本サービスの全部又は一部の廃止が利用者に重大な影響を与えると判断する場合、廃止の1か月以上前までに、利用者に対して、本アプリ上への掲載その他当社が適切と判断する方法で周知するものとします。ただし、緊急性がある等やむを得ない場合は、当該周知を行わないことがあります。なお、本サービスの全部が廃止された場合は、当該時点をもって利用登録は自動的に解除されるものとします。
                </li>
              </ul>
            </section>
            <section>
              <h3>第15条（バージョンアップ等）</h3>
              <ul>
                <li>1.第14条（本サービスの変更、追加及び廃止）の場合、本アプリの再ダウンロード又はバージョンアップが必要となることがあります。</li>
                <li>2.前項に基づき本アプリのバージョンアップを行う場合、当該バージョンアップが完了するまでの間、本サービスの全部又は一部を利用することができないことがあります。</li>
              </ul>
            </section>
            <section>
              <h3>第16条（本サービスに関する不保証等）</h3>
              <ul>
                <li>
                  1.本アプリ及び本サービスは診断行為、診療行為及び治療行為を提供するものではなく、医業を目的に行うものではありません。当社は、本サービスについて、利用者の特定の利用目的への適合性、分析結果その他提供する情報の完全性、有用性、的確性、信頼性、即時性等について何ら保証するものではなく、これらに関連して利用者に損害が生じたとしても責任を負いません。
                </li>
                <li>2.利用者は、前項に定める内容を十分に認識した上で、自己の責任において本サービスを利用するものとし、本サービスの利用の有無及びその内容にかかわらず、利用者自身の判断で適切な医療機関で医療を受けるものとします。</li>
                <li>
                  3.本サービスには、当社が所有又は管理しない第三者のウェブサイトやアプリ等（Sign in with
                  Apple及びGoogleサインインを含み、以下「第三者サービス」といいます。）へのリンクを含むことがあります。利用者が、第三者サービスへのリンクを経由して、これらの第三者サービス又は第三者が提供するアプリケーション若しくはコンテンツ（以下、総称して「第三者コンテンツ」といいます。）を利用する場合、当該第三者の定める利用規約及びプライバシーポリシーに従い、自らの責任と費用でこれを利用するものとします。これらの第三者サービス及び第三者コンテンツについて、当社は責任を負いません。
                </li>
              </ul>
            </section>
            <section>
              <h3>第17条（当社の免責事項）</h3>
              <ul>
                <li>
                  1.当社は、次の各号に定める事項については一切の責任を負わないものとし、利用者が自己の責任で解決するものとします。ただし、本アプリに関する当社と利用者との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、本項は適用されません。
                  <ol>
                    <li>(1)本規約の規定に違反した結果、利用者及び第三者に生じた損害</li>
                    <li>(2)本アプリの提供、提供の遅滞、制限事項、変更、追加、廃止、一時停止及び中止、並びに本サービスを通じて提供される情報及び本サービスに係るデータ等の消失による利用者の損害</li>
                    <li>(3)利用者が本アプリを利用したこと、又は利用しなかったこと、その他本アプリに関連して利用者に生じた損害</li>
                  </ol>
                </li>
                <li>
                  2.前項ただし書に定める場合その他当社の損害賠償責任を免責する規定にかかわらず、消費者契約法の適用その他の理由により、当社が利用者に対して損害賠償責任を負う場合においても、当社が利用者に対して負う責任の範囲は、当社の故意又は重過失がない限り、当社による債務不履行又は不法行為により利用者に生じた損害のうち、現実に発生した直接かつ通常の損害に限り、かつ、損害発生時から遡って1年以内に利用者が当社に支払った利用料相当額を上限とします。
                </li>
              </ul>
            </section>
            <section>
              <h3>第18条（反社会的勢力の排除等）</h3>
              <ul>
                <li>
                  1.利用者は、当社に対し、次の各号に定める事項を現在及び将来にわたって表明し、保証するものとします。
                  <ol>
                    <li>
                      (1)自ら（法人その他の団体にあっては、自らの役員を含みます。）が、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団等その他これらに準じる者（以下、総称して「反社会的勢力」といいます。）に該当しないこと
                    </li>
                    <li>(2)利用者が法人その他の団体の場合にあっては、反社会的勢力が経営を支配していると認められる関係を有しないこと</li>
                    <li>(3)利用a者が法人その他の団体の場合にあっては、反社会的勢力が経営に実質的に関与していると認められる関係を有しないこと</li>
                    <li>(4)自ら若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもって取引を行う等、反社会的勢力を利用していると認められる関係を有しないこと</li>
                    <li>(5)反社会的勢力に対して資金等を提供又は便宜を供与する等の関与をしていると認められる関係を有しないこと</li>
                    <li>(6)利用者が法人その他の団体の場合にあっては、自らの役員又は自らの経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有しないこと</li>
                  </ol>
                </li>
                <li>
                  2.利用者は、自ら又は第三者を利用して次の各号に該当する行為を行わないことを保証するものとします。
                  <ol>
                    <li>(1)暴力的な要求行為</li>
                    <li>(2)法的な責任を超えた不当な要求行為</li>
                    <li>(3)取引に関して、脅迫的な言動を用いる、又は暴力を用いる行為</li>
                    <li>(4)風説を流布し、偽計を用い、又は威力を用いて、当社の信用を毀損し、又は当社の業務を妨害する行為</li>
                    <li>(5)その他前各号に準ずる行為</li>
                  </ol>
                </li>
              </ul>
            </section>
            <section>
              <h3>第19条（規約の変更）</h3>
              当社は、当社が必要と認めた場合、本規約の内容を変更することができるものします。本規約の内容を変更する場合、当社は、効力発生日の1か月以上前（当該変更による利用者の不利益の程度が軽微であると当社が判断した場合、その期間を短縮することができるものとします。）までに、本アプリ上への掲載その他当社が適切と判断した方法により、変更後の内容及びその効力発生日を周知するものとし、本規約の変更の効力は当該効力発生日に生じるものとします。ただし、法令上、変更に利用者の同意が必要となる場合には、当社所定の方法により、当該変更につき利用者の同意を得るものとします。
            </section>
            <section>
              <h3>第20条（分離可能性）</h3>
              本規約のいずれかの条項の全部又は一部が無効又は違法となった場合であっても、当該無効又は違法は、いかなる意味でも本規約の他の条項並びにその解釈及び適用に影響せず、これらの違法性及び有効性を損なわず、またこれらを無効にするものではありません。
            </section>
            <section>
              <h3>第21条（権利の譲渡等）</h3>
              <ul>
                <li>1.利用者は、利用契約に基づき、当社に対して有する権利又は当社に対して負う義務の全部又は一部を第三者に譲渡し、承継させ、又は担保提供その他の処分をすることはできません。</li>
                <li>
                  2.当社が、本サービスに関する事業を第三者に譲渡、承継その他処分（事業譲渡又は会社分割による場合を含みます。）する場合には、当該譲渡等に伴い、本規約上の地位及び本規約に基づく権利若しくは義務並びに利用者の情報は当該譲渡等の譲受人に承継されるものとし、利用者は、かかる承継につきあらかじめ同意するものとします。
                </li>
              </ul>
            </section>
            <section>
              <h3>第22条（準拠法及び管轄裁判所）</h3>
              本規約の準拠法は日本法とし、本規約又は本サービスに起因し又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
            </section>
          </div>
        </div>
      </div>
    </div>
    <!--/.primary-content-->
  </main>
  <!-- e: main-content -->
  <Footer />
</template>

<script>

import Header from '../../components/jp/Header.vue'
import Footer from '../../components/jp/Footer.vue'

export default {
  name: 'zm-terms',
  components: {
    Header,
    Footer
  }
}
</script>