<template>
	<Header/>
	<!-- main-content -->
    <main class="main-content">
      <!-- sub-banner -->
      <section class="sub-banner form-floating bg-black-3">
        <div class="container form-floating">
          <div class="row align-items-center">
            <div class="col-md-4 col-lg-4">
              <div class="sub-banner-img">
                <img class="img-shadow-2" src="/images/upload/04-record-result.png" alt="" />
              </div>
            </div><!--/.col-->
            <div class="col-md-8 col-lg-6">
              <div class="sub-banner-content text-center ps-lg-3">
                <div class="sub-banner-header">
                  <p class="sub-banner-sub-label">睡眠時呼吸音測定アプリ ズーメーター</p>
                  <div class="logo-zzzmeter">
                    <span class="logo-img">
                    <img src="/images/logo_84x84.png" alt="..." />
                    </span>
                    <span class="logo-text">ZzzMeter</span>
                  </div>
                </div>
                <button class="a-btn cursor-auto"><span>法人向けプラン</span></button>
                <h2 class="sub-banner-title">まずはお気軽に<br />お問い合わせ・資料請求</h2>
                <div class="sub-banner-text">法人のお客様向けに従業員様の人数に合わせたプランをご用意しております。<br />詳しくはお問い合わせ・資料請求にてご案内します。</div>
              </div>
            </div><!--/.col-->
          </div>
        </div>
      </section>
      <!-- e: sub-banner -->

      <!-- section-contact -->
      <section class="section-contact">
        <div class="container form-floating">
          <div class="contact-box">
            <ul class="nav nav-tabs nav-tabs-custom">
              <li class="nav-item">
                <button class="nav-link" v-bind:class="{ active: this.$route.query.tab == 'inquiry' }" id="inquiry-tab" data-bs-toggle="tab" data-bs-target="#inquiry"
                  type="button" role="tab" aria-controls="inquiry" aria-selected="true" @click="changeTab('inquiry')">
                  <span>お問い合わせ</span>
                </button>
              </li>
              <li class="nav-item">
                <button class="nav-link" v-bind:class="{ active: this.$route.query.tab == 'form' }" id="form-tab" data-bs-toggle="tab" data-bs-target="#form" type="button"
                  role="tab" aria-controls="form" aria-selected="false" @click="changeTab('form')">
                  <span>資料請求</span>
                </button>
              </li>
            </ul>
            <div class="tab-content spacing" id="nav-tabContent">
              <div class="tab-pane fade" v-bind:class="{ 'active show': this.$route.query.tab == 'inquiry' }" id="inquiry" role="tabpanel" aria-labelledby="inquiry-tab">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contact-info ps-3 mb-4">
                      <h3 class="contact-info-title">お問い合わせ</h3>
                      <div class="contact-info-text">
                        従業員の健康サポートの一環として、法人・団体様向けに特別なプランをご用意しております。ご興味のある方や疑問点がある方はお問い合わせフォームよりご連絡ください。</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="contact-form">
                      <form class="form">
                        <div class="row gx-2">
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label for="formControl1" class="form-label">会社名 <span
                                  class="text-required">必須</span></label>
                              <input type="text" class="form-control" id="formControl1" placeholder="" v-model="fields.company_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.company_name.$error">必須項目に入力してください。</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label for="formControl1" class="form-label">従業員規模 <span
                                  class="text-any">任意</span></label>
                              <select class="form-select" v-model="fields.company_size">
                                <option value=""> --- </option>
                                <option value="1-10"> 1 - 10 </option>
                                <option value="10-50">10 - 50 </option>
                                <option value="50-100">50 - 100 </option>
                                <option value=">100"> > 100 </option>
                              </select>
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="row gx-2">
                          <div class="col-12">
                            <label class="form-label">ご担当者様のお名前 <span class="text-required">必須</span></label>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" placeholder="姓" v-model="fields.first_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.first_name.$error || v$.fields.last_name.$error">必須項目に入力してください。</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" id="formControl2" placeholder="名" v-model="fields.last_name">
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="form-label">役職 <span class="text-any">任意</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.position">
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">メールアドレス <span class="text-required" >必須</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.email">
                          <div class="invalid-feedback" style="display: block" v-if="v$.fields.email.$error">必須項目に入力してください。</div>
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">電話番号 <span class="text-any">任意</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.phone_no">
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">お問い合わせ内容 <span class="text-required">必須</span></label>
                          <textarea class="form-control" rows="6" cols="10" v-model="fields.content"></textarea>
                          <div class="invalid-feedback" style="display: block" v-if="v$.fields.content.$error">必須項目に入力してください。</div>
                        </div><!-- form-group -->
                        <div class="form-check form-check-custom">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="accepted">
                          <label class="form-check-label" for="exampleCheck1">個人情報の取扱について、ZzzMeter®の<a href="/privacy-policy">
                              プライバシーポリシー</a>に同意する</label>
                        </div><!-- form-check -->
                        <div class="form-bottom">
                          <button type="button" class="btn btn-custom btn-xl btn-purle-1 w-100 rounded-pill btn-arrow" @click="submitForm" :disabled="!this.accepted">送信
                            <i class="arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- tab 1 -->
              <div class="tab-pane fade" v-bind:class="{ 'active show': this.$route.query.tab == 'form' }"  id="form" role="tabpanel" aria-labelledby="form-tab">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contact-info ps-3 mb-4">
                      <h3 class="contact-info-title">資料請求</h3>
                      <div class="contact-info-text">
                        資料の送り先をフォームに入力し、送信をお願いします。<br />
                        ZzzMeterのサービスを紹介した「サービス紹介」資料をメールでお送りいたします。</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="contact-form">
                      <form class="form">
                        <div class="row gx-2">
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label class="form-label">会社名 <span class="text-required">必須</span></label>
                              <input type="text" class="form-control" placeholder="" v-model="fields.company_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.company_name.$error">必須項目に入力してください。</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <label class="form-label">従業員規模 <span class="text-any">任意</span></label>
                              <select class="form-select" v-model="fields.company_size">
                                <option value=""> --- </option>
                                <option value="1-10"> 1 - 10 </option>
                                <option value="10-50">10 - 50 </option>
                                <option value="50-100">50 - 100 </option>
                                <option value=">100"> > 100 </option>
                              </select>
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="row gx-2">
                          <div class="col-12">
                            <label class="form-label">ご担当者様のお名前 <span class="text-required">必須</span></label>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" placeholder="姓" v-model="fields.first_name">
                              <div class="invalid-feedback" style="display: block" v-if="v$.fields.first_name.$error || v$.fields.last_name.$error">必須項目に入力してください。</div>
                            </div><!-- form-group -->
                          </div>
                          <div class="col-md-6">
                            <div class="form-group mb-2">
                              <input type="text" class="form-control" id="formControl2" placeholder="名" v-model="fields.last_name">
                            </div><!-- form-group -->
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="form-label">役職 <span class="text-any">任意</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.position">
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">メールアドレス <span class="text-required">必須</span></label>
                          <input type="email" class="form-control" placeholder="" v-model="fields.email">
                          <div class="invalid-feedback" style="display: block" v-if="v$.fields.email.$error">必須項目に入力してください。</div>
                        </div><!-- form-group -->
                        <div class="form-group mb-2">
                          <label class="form-label">電話番号 <span class="text-any">任意</span></label>
                          <input type="text" class="form-control" placeholder="" v-model="fields.phone_no">
                        </div><!-- form-group -->
                        <div class="form-check form-check-custom">
                          <input type="checkbox" class="form-check-input" id="exampleCheck2" v-model="accepted">
                          <label class="form-check-label" for="exampleCheck2">個人情報の取扱について、ZzzMeter®の<a href="/privacy-policy">
                              プライバシーポリシー</a>に同意する</label>
                        </div><!-- form-check -->
                        <div class="form-bottom">
                          <button type="button" @click="submitForm" class="btn btn-custom btn-xl btn-purle-1 w-100 rounded-pill btn-arrow" :disabled="!this.accepted">送信
                            <i class="arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- tab 2 -->
            </div>
          </div>
        </div>
      </section>
      <!-- e: section-contact -->
    </main>
    <!-- e: main-content -->
	<Footer/>
</template>

<script>

import Header from '../../components/jp/Header.vue'
import Footer from '../../components/jp/Footer.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from 'axios'

export default {
  name: 'zm-contact',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      v$: useVuelidate(),
      fields: {
        company_name: "",
        company_size: "",
        first_name: "",
        last_name: "",
        email: "",
        content: "",
        phone_no: "",
        position: "",
        type: this.$route.query.tab,
      },
      accepted: false,
    };
  },
  validations() {
    return {
      fields: {
        company_name: { required },
        first_name: { required },
        last_name: { required },
        email: { required, email },
        content: this.fields.type == 'inquiry'? { required } : {},
        phone_no: {},
        company_size: {},
      },
      accepted: { required },
    };
  },
  methods: {
    changeTab(type) {
      this.fields.type = type;
    },
    async submitForm() {
      this.v$.fields.$validate();
      const api = process.env.VUE_APP_API_URL + "contact_mail";
      if (!this.v$.fields.$error && this.accepted) {
          await axios.post(api, this.fields)
          .then( () => {
            window.location.href = '/thanks';
          })
          .catch( error => {
            alert('Something went wrong \n Please try again later.')
            console.log("ERROR", error);
          })
      } else {
        console.log("Form validation failed");
      }
    },
  },
}
</script>