<template>
  <Header2 />
  <!-- main-content -->
  <main class="main-content">
    <div class="container">
      <div class="thanks-content">
        <h2>Thanks</h2>
        <div class="expert">
          <p>
            お問い合わせありがとうございました！<br />
            ご記入いただいた内容は無事送信されました。後ほど担当者よりメールにてご連絡差し上げます。<br />
            ご連絡まで今しばらくお待ちください。
          </p>
        </div>
      </div>
      <!--/.thanks-content-->
    </div>
  </main>
  <!-- e: main-content -->
  <Footer2 />
</template>

<script>

import Header2 from '../../components/jp/Header2.vue'
import Footer2 from '../../components/jp/Footer2.vue'

export default {
  name: 'zm-thanks',
  components: {
    Header2,
    Footer2
  },
}
</script>