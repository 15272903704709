<template>
  <Header />
  <!-- main-content -->
  <main class="main-content">
    <!-- section-banner -->
    <section class="section-banner form-floating">
      <div class="container">
        <div class="wrap-1070 mx-auto">
          <div class="row align-items-center">
            <div class="col-lg-5 order-lg-last">
              <div class="banner-media">
                <img src="/videos/ZzzMeter_LP_top.gif" alt="" />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="banner-content">
                <h2 class="title title-type-1">Are you sure<br />you're breathing<br />well while asleep?</h2>
                <p class="banner-text">ZzzMeter, a sleep breath sound measurement application</p>
                <div class="logo-zzzmeter">
                  <span class="logo-img">
                    <img src="/images/logo_84x84.png" alt="..." />
                  </span>
                  <span class="logo-text">ZzzMeter</span>
                </div>
                <div class="download-app lg">
                  <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
                    <img src="/images/upload/en_app-store-lg.png" class="img-fluid" alt="" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
                    <img src="/images/upload/en_google-play-lg.png" class="img-fluid" alt="" />
                  </a>
                </div>
                <p class="banner-notice">This product is intended to be used for health control but not a medical device.
                </p>
              </div>
              <div class="img-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- e: section-banner -->

    <!-- section-intro -->
    <section class="section-intro form-floating">
      <div class="container">
        <div class="wrap-1070 mx-auto">
          <div class="row align-items-center">
            <div class="col-lg-6 order-lg-last">
              <div class="banner-media">
                <img src="/videos/ZzzMeter_LP_2edview.gif" alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="section-text">
                <h3 class="title title-type-3">Visualizing the intervals<br />that you are not seemed to be breathing
                  and<br /> frequency of breath by<br />sleep breath sound.</h3>
                <div class="text-none">ZzzMeter visualizes the time interval that you are not appeared to be breathing by
                  blanking the chart from the recorded data of sleep breath sound. It helps you realize the average rates
                  of the number of times and the longest intervals that are not supposed to breathe in an hour.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- e: section-intro -->

    <!-- section-issues -->
    <section id="problems" ref="problems" class="section-issues">
      <div class="container">
        <div class="wrap-1070 mx-auto">
          <div id="c-problem" class="problem-container">
            <h3 class="title title-type-1 text-center">Are you struggling with these problems?</h3>
            <div class="problems">
              <div class="problem fadeIn wow" style="animation-delay: 0.2s;">
                <div class="wow shake bubble">
                  <div class="problem-text">Reported that you’re<br /><span>snoring</span><br />by surrounding people
                  </div>
                </div>
              </div>
              <div class="problem fadeIn wow" style="animation-delay: 0.8s;">
                <div class="wow shake bubble">
                  <div class="problem-text">Reported that you stop<br /><span>breathing</span><br /> during sleep</div>
                </div>
              </div>
              <div class="problem fadeIn wow" style="animation-delay: 0.4s;">
                <div class="wow shake bubble">
                  <div class="problem-text">Feel strong<br /><span>drowsiness</span><br />in daytime</div>
                </div>
              </div>
              <div class="problem fadeIn wow" style="animation-delay: 0.6s;">
                <div class="wow shake bubble">
                  <div class="problem-text">Fall<br /><span>asleep</span><br />during meetings</div>
                </div>
              </div>
              <div class="problem fadeIn wow" style="animation-delay: 1.0s;">
                <div class="wow shake bubble">
                  <div class="problem-text">Feeling<br /><span>tired</span><br />all day long</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- e: section-intro -->

    <!-- section-issues -->
    <section class="section-issues">
      <div class="container">
        <div class="wrap-1070 mx-auto block-content bg-black-3">
          <h2 class="title-block d-lg-none">The problems are<br />actually very familiar.</h2>
          <div class="row align-items-center">
            <div class="col-lg-5 col-chart order-lg-last">
              <div id="chart" class="chart-container mx-auto mx-lg-0 mb-3 mb-lg-0"></div>
            </div><!--/.col-->
            <div class="col-lg-7 col-text">
              <h2 class="title-block d-none d-lg-block">The problems are<br />actually very familiar.</h2>
              <div class="desc">
                <div class="wrap-440 mb-4 pb-lg-3">
                  According to the study of our company, in a certain enterprise, 70 out of 116 people, which is 60% of
                  measurement targeted people, are counted more than 15 times of their intervals that seem not breathing
                  during sleep.
                </div>
                <ul class="notices list-unstyled">
                  <li>* The count means the average number of counts that are not seemed to be breathing in an hour.</li>
                  <li>* We count as one if 10 seconds had passed that you are not seemed to be breathing.</li>
                </ul>
              </div>
            </div><!--/.col-->
          </div>
        </div><!--/.block-content-->
      </div>
    </section>
    <!-- e: section-issues -->

    <!-- section-issues -->
    <section class="section-issues">
      <div class="container">
        <div class="wrap-1070 mx-auto block-content bg-black-3">
          <div class="row">
            <div class="col-lg-5 col-sm">
              <h2 class="title-block">This might be<br />caused by the breathing problems</h2>
            </div><!--/.col-->
            <div class="col-lg-7 col-lg pt-2 pt-lg-0">
              <div class="desc pt-lg-2 mt-lg-1">
                <p class="mb-3 mb-md-4 fz-18">If you have problems such as snoring or causing situation that are not
                  seemed to be breathing during sleep.</p>
                <div class="list-circle-text">
                  <div class="circle-text wow fadeInUp" style="animation-delay: .1s;"><span>Decreasing quality of
                      sleep</span></div>
                  <div class="circle-text wow fadeInUp" style="animation-delay: .2s;"><span>Having trouble getting
                      up</span></div>
                  <div class="circle-text wow fadeInUp" style="animation-delay: .3s;"><span>Drowsiness in daytime</span>
                  </div>
                  <div class="circle-text wow fadeInUp" style="animation-delay: .4s;"><span>Irritability</span></div>
                </div>
                <p class="note-2">
                  If it gets worse, the problems cause a risk of depressions or lifestyle diseases or Sleep Apnea Syndrome
                  (SAS).
                </p>
              </div>
            </div><!--/.col-->
          </div>
        </div><!--/.block-content-->
      </div>
    </section>
    <!-- e: section-issues -->

    <!-- section-easy-to-use -->
    <section id="advertisement" ref="advertisement" class="section-easy-to-use">
      <div class="container">
        <div class="wrap-1070 mx-auto block-content pb-0 bg-black-3 mb-0">
          <div class="heading text-center">
            <h2 class="title-block-big">Easy Measurement with ZzzMeter</h2>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6 text-center mb-4 mb-lg-0">
              <img src="/videos/ZzzMeter_LP_about.gif" alt="" />
            </div><!--/.col-->
            <div class="col-lg-6">
              <div class="wrap-text">
                <h3 class="text-center text-lg-start">ZzzMeter is...</h3>
                <p class="desc">an app to count the number of the intervals<br />
                  that seem not breathing by recording the<br />
                  breathing sound during sleep.</p>
              </div>
            </div><!--/.col-->
          </div>
        </div><!--/.block-content-->
      </div>
    </section>
    <!-- e: section-easy-to-use -->

    <!-- section-download-app -->
    <section id="download" ref="download" class="section-download-app text-center text-white mb-0">
      <div class="container">
        <div class="sub-text">ZzzMeter, a sleep breath sound measurement application</div>
        <div class="logo-zzzmeter">
          <span class="logo-img">
            <img src="/images/logo_84x84.png" alt="">
          </span>
          <span class="logo-text">ZzzMeter</span>
        </div>
        <h3 class="sub-text-2 fw-bold">Download the app</h3>
        <div class="download-app lg">
          <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
            <img src="/images/upload/en_app-store-lg.png" alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
            <img src="/images/upload/en_google-play-lg.png" alt="" />
          </a>
        </div>
        <a href="/en/contact?tab=form" class="link-download-document rounded-pill is-primary">Request Documents and
          Customer Service Inquiry for Enterprises</a>
      </div>
    </section>
    <!-- e: section-download-app -->

    <div class="group-section-bg-grey">
      <!--section-steps-->
      <section class="section-steps">
        <div class="container">
          <div class="wrap-1119 mx-auto">
            <div class="heading-line text-end mb-lg-0">
              <h2 class="title-heading-line">Easy to use</h2>
            </div><!--/.heading-->
            <div class="row mb-4 mb-md-0 g-0 list-steps justify-content-center">
              <div class="col-md-6 col-lg-4 col-item wow fadeInUp" style="animation-delay: 0.15s;">
                <div class="card-step">
                  <div class="card-step-number">01</div>
                  <div class="card-step-context">
                    <h3 class="card-step-title">Answer some questions for registration</h3>
                  </div>
                  <div class="card-step-imgs">
                    <div class="group-circles"></div>
                    <img src="/images/upload/01-register-physical.png" class="img-shadow-1" alt="" />
                  </div>
                </div><!--/.card-step-->
              </div><!--/.col-->

              <div class="col-md-6 col-lg-4 col-item wow fadeInUp" style="animation-delay: 0.3s;">
                <div class="card-step">
                  <div class="card-step-number">02</div>
                  <div class="card-step-context">
                    <h3 class="card-step-title">Place your smartphone by your pillow and press “Record” before you sleep.
                    </h3>
                  </div>
                  <div class="card-step-imgs">
                    <div class="group-circles"></div>
                    <img src="/images/upload/02-home-en.png" class="img-shadow-1" alt="" />
                  </div>
                </div><!--/.card-step-->
              </div><!--/.col-->

              <div class="col-md-6 col-lg-4 col-item wow fadeInUp" style="animation-delay: 0.45s;">
                <div class="card-step">
                  <div class="card-step-number">03</div>
                  <div class="card-step-context">
                    <h3 class="card-step-title">Upload the recorded data when you wake up.</h3>
                  </div>
                  <div class="card-step-imgs">
                    <div class="group-circles"></div>
                    <img src="/images/upload/04-recording-en.png" class="img-shadow-2" alt="" />
                  </div>
                </div><!--/.card-step-->
              </div><!--/.col-->
            </div>

            <div class="heading-line text-start d-lg-none">
              <h2 class="title-heading-line">Check your results</h2>
            </div><!--/.heading-->
            <div class="row">
              <div class="col-md-7 text-center text-md-start img-result mb-4 mb-md-0 order-md-last">
                <img src="/images/upload/04-record-result_rev.png" class="img-shadow-3" alt="" />
              </div><!--/.col-->
              <div class="col-md-5">
                <div class="heading-line text-start mb-0 d-none d-lg-block">
                  <h2 class="title-heading-line">Check your results</h2>
                </div><!--/.heading-->
                <div class="result-text">
                  The app shows you the situation of the breathing sound in annulus chart, detecting the sound of
                  breathing from the recorded data that you had uploaded. It displays the number of the term when the
                  breathing sound is not heard, which we define intervals that are not seemed to be breathing.
                </div>
              </div><!--/.col-->
            </div>
          </div>
        </div>
      </section>
      <!--e: section-steps-->

      <!-- section-tips -->
      <section id="price" ref="price" class="section-tips">
        <div class="container">
          <div class="heading text-center">
            <h2 class="title-heading">We suggest you continue<br />using the app not only once</h2>
            <div class="sub-heading">
              <p>We are in the era of monitoring sleep to stay fit mentally and physically.</p>
              <p>※ Charge will be paid as in-app billing.</p>
            </div>
          </div>
          <div class="wrap-1070 mx-auto">
            <div class="row row-lg">
              <div class="col-md-4">
                <div class="card-tip">
                  <div class="card-tip-text">For whom prefer to try<br />it out first</div>
                  <div class="card-tip-btn">
                    <div class="btn-item btn-purle-1 w-100 cursor-auto">Plan to purchase for one time</div>
                  </div>
                </div>
              </div><!--/.col-->

              <div class="col-md-4">
                <div class="card-tip">
                  <div class="card-tip-text">For whom prefer to check<br />when you want to</div>
                  <div class="card-tip-btn">
                    <div class="btn-item btn-purle-2 w-100 cursor-auto">Plan to purchase for three times</div>
                  </div>
                </div>
              </div><!--/.col-->

              <div class="col-md-4">
                <div class="card-tip">
                  <div class="card-tip-text">For whom prefer to check<br />regularly to know your tendency</div>
                  <div class="card-tip-btn">
                    <div class="btn-item btn-purle-3 w-100 cursor-auto">Annual Plan, five times per month</div>
                  </div>
                </div>
              </div><!--/.col-->
            </div>
          </div>
          <div class="our-plan mx-auto text-center">
            <h3>Corporate Services</h3>
            <div class="expert fw-bold mb-4">
              We have plans for corporate clients according to the number of employees.<br />
              Please contact us for more details or visit our website for requesting documents.
            </div>
            <a href="/en/contact?tab=form" class="btn btn-custom btn-primary-1 rounded-pill">For corporates clients,
              please contact us or request documents from here</a>
          </div><!--/.our-plan-->
        </div>
      </section>
      <!-- e: section-tips -->
    </div>

    <!-- section-download-app -->
    <section class="section-download-app text-center text-white">
      <div class="container">
        <div class="sub-text">ZzzMeter, a sleep breath sound measurement application</div>
        <div class="logo-zzzmeter">
          <span class="logo-img">
            <img src="/images/logo_84x84.png" alt="">
          </span>
          <span class="logo-text">ZzzMeter</span>
        </div>
        <h3 class="sub-text-2 fw-bold">Download the app</h3>
        <div class="download-app lg">
          <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
            <img src="/images/upload/en_app-store-lg.png" alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
            <img src="/images/upload/en_google-play-lg.png" alt="" />
          </a>
        </div>
        <a href="/en/contact?tab=form" class="link-download-document rounded-pill">Request Documents and Customer Service
          Inquiry for Enterprises</a>
      </div>
    </section>
    <!-- e: section-download-app -->

    <!-- section-faqs -->
    <section id="faqs" ref="faqs" class="section-faqs">
      <div class="container">
        <div class="heading text-center">
          <h2 class="title-heading">FAQs</h2>
        </div>
        <div class="wrap-886 mx-auto">
          <div class="accordion accordion-cs" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">I woke up and found that the recording display disappeared and just
                    showed the startup screen</span>
                </button>
              </h2>
              <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  It might have been shut down due to the lack of storage. Please try restarting your smartphone so that
                  the piled cash data (browsing data) will be deleted.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">Would it be okay to keep using my smartphone as an alarm
                    clock?</span>
                </button>
              </h2>
              <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Basically, it isn’t a problem to use the alarm clock while recording. However, there are possibilities
                  if something may cause troubles, your display may show initial screen. In case it happened, we recommend
                  you reboot your smartphone. It may be caused by storage shortage or a heavy load for the smartphone.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">Would it be okay to use my smartphone such as opening the other app
                    or watching YouTube videos while recording?</span>
                </button>
              </h2>
              <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  You can touch your smartphone but when you finished operating it, you should go back to the recording
                  screen. You can restart when the screen display telling you to restart. Please Keep the app open while
                  recording because some types of Android equipment may lose the recording data.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">Would the app be no problem to keep analyzing if it happened to
                    record the sounds except for the breathing sound of the user?</span>
                </button>
              </h2>
              <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  It detects the sound of breathing by reducing the sound caused in daily life such as telephone ringtone,
                  ambulance siren and car hones from the recording data of the users.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">I have trouble uploading the data.</span>
                </button>
              </h2>
              <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Please try again in the better Wi-Fi environment.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">Where should I leave my smartphone when sleeping?</span>
                </button>
              </h2>
              <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Please place it by your pillow before you go to bed.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading7">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">The display shows that I have failed uploading the data. Should I
                    try it again?</span>
                </button>
              </h2>
              <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  When you failed uploading the data, please check the list of menus and try again when it shows the
                  button to tell you to do it again.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading8">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">Should I adjust the volume control before the recording start on
                    the smartphone?</span>
                </button>
              </h2>
              <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  You don’t have to. The app can be used as it is with the default settings when you get your smartphone.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">How many hours should I need to sleep?</span>
                </button>
              </h2>
              <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Sleeping for six hours should be ideal, but if it is difficult for you, we recommend trying using the
                  app when you can take enough time for sleeping.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading10">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">May I answer the phone call if I receive it during the
                    night?</span>
                </button>
              </h2>
              <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  No problem. However, the recording may be stopped when you answer the phone call. Upload the recorded
                  data by then once and press the restart button before you fall asleep again.
                </div>
              </div>
            </div><!--/.accordion-item-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                  <span class="label-question">Q</span>
                  <span class="txt-question flex-fill">Can I use the app in any kinds of smartphone?</span>
                </button>
              </h2>
              <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  You can use the app in any types.
                </div>
              </div>
            </div><!--/.accordion-item-->
          </div><!--/.accordion-->
        </div>
      </div>
    </section>
    <!-- e: section-faqs -->

    <!-- section-about-us -->
    <section id="about_us" ref="about_us" class="section-about-us">
      <div class="container">
        <div class="heading text-center">
          <h2 class="title-heading">Company Profile</h2>
        </div>
        <div class="wrap-520 mx-auto">
          <div class="about-item">
            <div class="about-item-left">Company Name</div>
            <div class="about-item-right">Okuchy, Inc.</div>
          </div><!--/.about-item-->

          <div class="about-item">
            <div class="about-item-left">Office location</div>
            <div class="about-item-right">
              106-0046<br />
              3-1-6 Motoazabu, Minato, Tokyo
            </div>
          </div><!--/.about-item-->

          <div class="about-item">
            <div class="about-item-left">Chairperson</div>
            <div class="about-item-right">Keiichi Kobayashi</div>
          </div><!--/.about-item-->

          <div class="about-item">
            <div class="about-item-left">CEO</div>
            <div class="about-item-right">Ayumi Hata</div>
          </div><!--/.about-item-->

          <div class="about-item">
            <div class="about-item-left">Date of establishment</div>
            <div class="about-item-right">4 February 2020</div>
          </div><!--/.about-item-->

          <div class="about-item">
            <div class="about-item-left">URL</div>
            <div class="about-item-right">https://okuchy.co.jp</div>
          </div><!--/.about-item-->
        </div>
      </div>
    </section>
    <!-- e: section-about-us -->
  </main>
  <!-- e: main-content -->
  <Footer />
</template>

<script>

import Header from '../../components/en/Header.vue'
import Footer from '../../components/en/Footer.vue'

export default {
  name: 'zm-home',
  components: {
    Header,
    Footer
  },
}
</script>